import Simple from '@/components/task-templates/Simple'

export default [
  {
    component: Simple,
    name: 'True и False',
    text: 'Ничего не дано. Вывести True и False как логические значения через пробел',
    note: null,
    input: 'ничего',
    output: 'True и False через пробел',
    examples: [
      {
        input: '',
        output: 'True False'
      }
    ]
  },
  {
    component: Simple,
    name: 'not',
    text: 'Дано логическое значение \\(a\\). Вывести противоположное значение',
    note: 'Используем оператор отрицания not',
    input: '\\(a\\) - логическое значение',
    output: 'True или False',
    examples: [
      {
        input: 'True',
        output: 'False'
      },
      {
        input: 'False',
        output: 'True'
      }
    ]
  },
  {
    component: Simple,
    name: 'and и or',
    text: 'Дано два логических значения \\(a\\) и \\(b\\). Вывести их конъюнкцию и дизъюнкцию через пробел',
    note: 'Конъюнкция (чаще всего называют оператор И) - это логический оператор, который даёт True только в случае, когда левая и правая части True. Ещё его иногда называют логическим умножением (если рассматривать True как 1, а False как 0, то результат будет 1 только когда оба множителя равны 1). В языке Python записывается словом and, например: True and False (результат будет False).<br>Дизъюнкция (чаще всего называют оператор ИЛИ) - это тоже логический оператор, который даёт False только в случае, когда левая и правая части False. Его иногда называют логическим сложением (если рассматривать True как 1, а False как 0, то результат будет 0 только когда оба слагаемых равны 0). В языке Python записывается словом and, например: True or False (результат будет True)',
    input: '\\(a\\) и \\(b\\) - логические значения',
    output: 'Два логических значения через пробел',
    examples: [
      {
        input: 'True, True',
        output: 'True True'
      },
      {
        input: 'True, False',
        output: 'False True'
      },
      {
        input: 'False, True',
        output: 'False True'
      },
      {
        input: 'False, False',
        output: 'False False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Операторы сравнения >, >=, <, <=',
    text: 'Дано два целых числа \\(a\\) и \\(b\\). Вывести через пробел результаты выражений \\(a > b\\), \\(a \\ge b\\), \\(a < b\\), \\(a \\le b\\). Понятно ли, в чём отличие?',
    note: '> - больше; >= - больше, либо равно; < - меньше; <= - меньше, либо равно',
    input: '\\(a\\) и \\(b\\) - целые',
    output: '4 логических значения через пробел',
    examples: [
      {
        input: '5, 6',
        output: 'False False True True'
      },
      {
        input: '6, 5',
        output: 'True True False False'
      },
      {
        input: '5, 5',
        output: 'False True False True'
      }
    ]
  },
  {
    component: Simple,
    name: 'Операторы сравнения == и !=',
    text: 'Дано два целых числа \\(a\\) и \\(b\\). Вывести через пробел результаты выражений \\(a = b\\), \\(a \\ne b\\)',
    note: '== - равно, != - не равно. Очень часто сравнение на равенство путают с оператором присваивания (=). Оператор = (один знак) служит для записи значений в переменную. Оператор == (два знака) служит для сравнения (когда хотим узнать, равны значения или нет). Если при сравнении писать = вместо ==, это приведёт к ошибке',
    input: '\\(a\\) и \\(b\\) - целые',
    output: '2 логических значения через пробел',
    examples: [
      {
        input: '5, 6',
        output: 'False True'
      },
      {
        input: '6, 5',
        output: 'False True'
      },
      {
        input: '5, 5',
        output: 'True False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Положительное',
    text: 'Дано целое число \\(a\\). Верно ли, что оно положительное?',
    note: null,
    input: '\\(a\\) - целое',
    output: 'True или False',
    examples: [
      {
        input: '5',
        output: 'True'
      },
      {
        input: '0',
        output: 'False'
      },
      {
        input: '-4',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Неотрицательное',
    text: 'Дано целое число \\(a\\). Верно ли, что оно неотрицательное?',
    note: null,
    input: '\\(a\\) - целое',
    output: 'True или False',
    examples: [
      {
        input: '5',
        output: 'True'
      },
      {
        input: '0',
        output: 'True'
      },
      {
        input: '-4',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётное',
    text: 'Дано целое число \\(a\\). Верно ли, что оно чётное?',
    note: null,
    input: '\\(a\\) - целое неотрицательное',
    output: 'True или False',
    examples: [
      {
        input: '5',
        output: 'False'
      },
      {
        input: '0',
        output: 'True'
      },
      {
        input: '6',
        output: 'True'
      }
    ]
  },
  {
    component: Simple,
    name: 'Делится или нет',
    text: 'Даны числа \\(a\\) и \\(b\\). Верно ли, что \\(a\\) не делится на \\(b\\)?',
    note: null,
    input: '\\(a\\) - целое неотрицательное, \\(b\\) - целое положительное',
    output: 'True или False',
    examples: [
      {
        input: '5, 3',
        output: 'True'
      },
      {
        input: '0, 2',
        output: 'False'
      },
      {
        input: '15, 3',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Оканчивается на 4 или 7',
    text: 'Дано число \\(a\\). Верно ли, что оно оканчивается на цифру 4 или 7?',
    note: null,
    input: '\\(a\\) - целое неотрицательное',
    output: 'True или False',
    examples: [
      {
        input: '4',
        output: 'True'
      },
      {
        input: '57',
        output: 'True'
      },
      {
        input: '75',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётное не с 4 на конце',
    text: 'Дано число \\(a\\). Верно ли, что оно чётное, которое оканчивается не на 4?',
    note: null,
    input: '\\(a\\) - целое неотрицательное',
    output: 'True или False',
    examples: [
      {
        input: '14',
        output: 'False'
      },
      {
        input: '57',
        output: 'False'
      },
      {
        input: '42',
        output: 'True'
      }
    ]
  },
  {
    component: Simple,
    name: 'Оба положительные',
    text: 'Даны два числа \\(a\\) и \\(b\\). Верно ли, что они оба положительные?',
    note: null,
    input: '\\(a\\), \\(b\\) - целые',
    output: 'True или False',
    examples: [
      {
        input: '5, 6',
        output: 'True'
      },
      {
        input: '0, 3',
        output: 'False'
      },
      {
        input: '-1, -2',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Есть положительное',
    text: 'Даны два числа \\(a\\) и \\(b\\). Верно ли, что хотя бы одно из них положительное?',
    note: null,
    input: '\\(a\\), \\(b\\) - целые',
    output: 'True или False',
    examples: [
      {
        input: '5, 6',
        output: 'True'
      },
      {
        input: '0, 3',
        output: 'True'
      },
      {
        input: '-1, -2',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разные знаки',
    text: 'Даны два целых числа \\(a\\) и \\(b\\). Верно ли, что одно из них положительное, а другое отрицательное?',
    note: 'Можно воспользоваться свойствами умножения. Произведение чисел с разными знаками всегда отрицательное',
    input: '\\(a\\), \\(b\\) - целые',
    output: 'True или False',
    examples: [
      {
        input: '5, -6',
        output: 'True'
      },
      {
        input: '-5, 6',
        output: 'True'
      },
      {
        input: '5, 6',
        output: 'False'
      },
      {
        input: '-5, -6',
        output: 'False'
      },
      {
        input: '5, 0',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Цепочка сравнений',
    text: 'Даны три целых числа \\(a\\), \\(b\\) и \\(c\\). Верно ли выражение \\(a < b < c\\)?',
    note: null,
    input: '\\(a\\), \\(b\\), \\(c\\) - целые',
    output: 'True или False',
    examples: [
      {
        input: '5, 10, 11',
        output: 'True'
      },
      {
        input: '5, 11, 10',
        output: 'False'
      },
      {
        input: '7, 7, 7',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Среднее число',
    text: 'Даны три целых числа \\(a\\), \\(b\\) и \\(c\\). Верно ли, что \\(b\\) не большее и не меньшее из чисел?',
    note: null,
    input: '\\(a\\), \\(b\\), \\(c\\) - целые',
    output: 'True или False',
    examples: [
      {
        input: '5, 10, 11',
        output: 'True'
      },
      {
        input: '11, 10, 5',
        output: 'True'
      },
      {
        input: '11, 10, 10',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Пара одинаковых',
    text: 'Даны числа \\(a\\), \\(b\\) и \\(c\\). Верно ли, что среди них есть хотя бы одна пара одинаковых?',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые положительные',
    output: 'True или False',
    examples: [
      {
        input: '1, 2, 3',
        output: 'False'
      },
      {
        input: '2, 2, 1',
        output: 'True'
      },
      {
        input: '4, 3, 3',
        output: 'True'
      },
      {
        input: '1, 2, 1',
        output: 'True'
      },
      {
        input: '5, 5, 5',
        output: 'True'
      }
    ]
  },
  {
    component: Simple,
    name: 'Все разные',
    text: 'Даны числа \\(a\\), \\(b\\) и \\(c\\). Верно ли, что все числа разные?',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые положительные',
    output: 'True или False',
    examples: [
      {
        input: '1, 2, 3',
        output: 'True'
      },
      {
        input: '2, 2, 1',
        output: 'False'
      },
      {
        input: '4, 3, 3',
        output: 'False'
      },
      {
        input: '1, 2, 1',
        output: 'False'
      },
      {
        input: '5, 5, 5',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чистая победа',
    text: 'Вася и Петя поспорили, кто из них решит больше задач за одинаковое время. Вам даны два числа \\(a\\) и \\(b\\) - количество задач, решенных Васей и Петей соответственно. Выведите, чистой ли была победа. Победа считается чистой, если победитель решил как минимум на две задачи больше, чем проигравший',
    note: 'Допускается не больше двух сравнений',
    input: '\\(a\\), \\(b\\) - целые неотрицательные',
    output: 'True или False',
    examples: [
      {
        input: '20, 15',
        output: 'True'
      },
      {
        input: '20, 22',
        output: 'True'
      },
      {
        input: '10, 11',
        output: 'False'
      },
      {
        input: '10, 10',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Двузначное',
    text: 'Дано целое число \\(a\\). Верно ли, что оно двузначное?',
    note: null,
    input: '\\(a\\) - целое положительное',
    output: 'True или False',
    examples: [
      {
        input: '50',
        output: 'True'
      },
      {
        input: '9',
        output: 'False'
      },
      {
        input: '100',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Нечётное трехзначное или четное двузначное',
    text: 'Дано число \\(a\\). Верно ли, что оно нечётное трехзначное или четное двузначное?',
    note: null,
    input: '\\(a\\) - целое положительное',
    output: 'True или False',
    examples: [
      {
        input: '8',
        output: 'False'
      },
      {
        input: '9',
        output: 'False'
      },
      {
        input: '51',
        output: 'False'
      },
      {
        input: '52',
        output: 'True'
      },
      {
        input: '100',
        output: 'False'
      },
      {
        input: '101',
        output: 'True'
      },
      {
        input: '9998',
        output: 'False'
      },
      {
        input: '9999',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Палиндром',
    text: 'Дано четырёхзначное число \\(a\\). Верно ли, что оно одинаково читается справа налево и слева направо?',
    note: 'Для решения задачи лучше всего создать 4 переменные (например, a1, a2, a3, a4), в которые занести соответствующие цифры',
    input: '\\(a\\) - целое положительное четырёхзначное',
    output: 'True или False',
    examples: [
      {
        input: '1001',
        output: 'True'
      },
      {
        input: '9999',
        output: 'True'
      },
      {
        input: '1233',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Существует ли треугольник',
    text: 'Даны числа \\(a\\), \\(b\\) и \\(c\\). Верно ли, что существует треугольник со сторонами \\(a\\), \\(b\\) и \\(c\\)?',
    note: 'Не существует треугольника, у которого одна сторона больше либо равна сумме двух других сторон.',
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые положительные',
    output: 'True или False',
    examples: [
      {
        input: '1, 1, 1',
        output: 'True'
      },
      {
        input: '3, 4, 5',
        output: 'True'
      },
      {
        input: '1, 2, 3',
        output: 'False'
      },
      {
        input: '7, 3, 3',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Белая клетка',
    text: 'Даны числа \\(x\\) и \\(y\\) - целые от 1 до 8, обозначают координаты клетки на шахматном поле. Известно, что клетка с координатами (1, 1) чёрная. Верно ли, что клетка с координатами \\((x,y)\\) белая?',
    note: null,
    input: '\\(x\\), \\(y\\) - целые от 1 до 8',
    output: 'True или False',
    examples: [
      {
        input: '1, 1',
        output: 'False'
      },
      {
        input: '1, 2',
        output: 'True'
      },
      {
        input: '4, 1',
        output: 'True'
      },
      {
        input: '8, 6',
        output: 'False'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последовательное накопление',
    text: 'Даны числа \\(a\\), \\(b\\) и \\(c\\). В первой строке выведите, является ли \\(a\\) положительным числом, во второй являются ли \\(a\\) и \\(b\\) одновременно положительными, в третьей являются ли все три числа одновременно положительными, соблюдая ограничения:<ul><li>допускается не более трёх сравнений на задачу</li><li>можно использовать только одну дополнительную переменную</li>',
    note: null,
    input: '\\(a\\), \\(b\\) и \\(c\\) - целые положительные',
    output: '3 логических значения на разных строках',
    examples: [
      {
        input: '-1, 0, 1',
        output: 'False\nFalse\nFalse'
      },
      {
        input: '7, -5, 6',
        output: 'True\nFalse\nFalse'
      },
      {
        input: '9, 6, -3',
        output: 'True\nTrue\nFalse'
      },
      {
        input: '6, 8, 5',
        output: 'True\nTrue\nTrue'
      }
    ]
  },
  {
    component: Simple,
    name: 'Високосный год',
    text: 'Дано число \\(n\\) - номер года. Верно ли, что этот год високосный?',
    note: 'Високосный год - это год, номер которого делится на 4, кроме случаев начала столетия. Год начала столетия (например, 1700, 1800, 1900, 2000) является високосным, только если делится на 400',
    input: '\\(a\\) - целое положительное',
    output: 'True или False',
    examples: [
      {
        input: '2020',
        output: 'True'
      },
      {
        input: '1996',
        output: 'True'
      },
      {
        input: '1990',
        output: 'False'
      },
      {
        input: '2000',
        output: 'True'
      },
      {
        input: '1900',
        output: 'False'
      }
    ]
  }
]
